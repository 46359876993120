import React from 'react';

const DashboardIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="3"
      y="3"
      width="7.65"
      height="5.4"
      rx="1"
      stroke="#A0A5B9"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <rect
      x="3"
      y="11.1"
      width="7.65"
      height="9.9"
      rx="1"
      stroke="#A0A5B9"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <rect
      x="13.3501"
      y="3"
      width="7.65"
      height="9.9"
      rx="1"
      stroke="#A0A5B9"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <rect
      x="13.3501"
      y="15.6"
      width="7.65"
      height="5.4"
      rx="1"
      stroke="#A0A5B9"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default DashboardIcon;
