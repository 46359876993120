import React from 'react';
import Input from '../../components/input';
import Button from '../../components/button';
import './style.css';
import { NavLink } from 'react-router-dom';
import Logo from '../../components/logo/Logo';

const Registration = props => {
  if (props.inviteCodeSuccess) {
    return (
      <div className="loginContainer">
        <div className="half left">
          <Logo />
          <div className="loginTitle">Зарегистрируйтесь</div>
          <Input
            type="name"
            title="Имя"
            value={props.name}
            placeholder="Имя"
            onChange={props.setName}
          />
          <Input
            type="name"
            title="Фамилия"
            value={props.lastName}
            placeholder="Фамилия"
            onChange={props.setLastName}
          />
          <Input
            type="email"
            title="Email"
            value={props.email}
            placeholder="Email"
            onChange={props.setEmail}
          />
          <Input
            type="password"
            title="Пароль"
            value={props.password}
            placeholder="Пароль"
            onChange={props.setPassword}
          />
          //
          <Button onClick={props.submit} textButton="Зарегистрироваться" />
          <NavLink to="/auth" className="moveToRegistration">
            Авторизоваться
          </NavLink>
        </div>
        <div className="half right">
          <img
            src={require('../../assets/pictures/school.svg')}
            alt="tLearn by Tapston"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="loginContainer">
        <div className="half left">
          <Logo />
          <div className="loginTitle">Зарегистрируйтесь</div>
          <Input
            type="name"
            title="Инвайт код"
            value={props.inviteCode}
            placeholder="Код"
            onChange={props.setInviteCode}
          />

          <Button onClick={props.checkCode} textButton="Применить" />
          <NavLink to="/auth" className="moveToRegistration">
            Авторизоваться
          </NavLink>
        </div>
        <div className="half right">
          <img
            src={require('../../assets/pictures/school.svg')}
            alt="tLearn by Tapston"
          />
        </div>
      </div>
    );
  }
};

export default Registration;
