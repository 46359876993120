import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';

import COLLECTIONS from '../constants';

const firebaseConfig = {
  apiKey: 'AIzaSyAPOWgYLgV0_vaosTvg6TQR9SWI_R1swmM',
  authDomain: 'tlearn-by-tapston.firebaseapp.com',
  databaseURL: 'https://tlearn-by-tapston.firebaseio.com',
  projectId: 'tlearn-by-tapston',
  storageBucket: 'tlearn-by-tapston.appspot.com',
  messagingSenderId: '440909206004',
  appId: '1:440909206004:web:734ab24c2fac79b2ece9a1',
  measurementId: 'G-0G97D6S805',
};

export const FIREBASE_CONFIG = {
  API_KEY: 'AIzaSyAPOWgYLgV0_vaosTvg6TQR9SWI_R1swmM',
  AUTH_DOMAIL: 'tlearn-by-tapston.firebaseapp.com',
  DATABASE_URL: 'https://tlearn-by-tapston.firebaseio.com/',
  PROJECT_ID: 'tlearn-by-tapston',
  STORAGE_BUCKET: 'tlearn-by-tapston.appspot.com',
};
const db = firebase.firestore;
const firestoreCollection = collection => db().collection(collection);

export const addInvitCode = async (code, groupId) => {
  try {
    if (groupId) {
      const res = await firestoreCollection(COLLECTIONS.INVITE_CODES)
        .doc(code)
        .set({
          code,
          group: groupId,
          role: 'student',
          active: true,
        });
      return res;
    } else {
      throw new Error();
    }
  } catch (err) {
    throw err;
  }
};

export const getStudentsFromGroup = async groupId => {
  try {
    if (groupId) {
      const res = await firestoreCollection(COLLECTIONS.STUDENTS)
        .where('group', '==', groupId)
        .get();
      return res.docs;
    } else {
      throw new Error('No groupId');
    }
  } catch (err) {
    console.log(err);
  }
};

export const createUser = async (uid, userData = {}) => {
  try {
    if (uid) {
      const res = await firestoreCollection(COLLECTIONS.TEACHERS)
        .doc(uid)
        .set({
          ...userData,
          id: uid,
        });
      return res;
    } else {
      throw new Error('No Id');
    }
  } catch (err) {
    console.log(err);
  }
};
export const updateUserData = async (uid, userData = {}) => {
  try {
    if (uid) {
      const res = await firestoreCollection(COLLECTIONS.TEACHERS)
        .doc(uid)
        .update(userData);
      return res;
    } else {
      throw new Error('No Id');
    }
  } catch (err) {
    console.log(err);
  }
};
export const getGroups = async uid => {
  try {
    if (uid) {
      const res = await firestoreCollection(COLLECTIONS.GROUPS)
        .where('teacher', '==', uid)
        .get();
      const result = res.docs.map(el => el.data());
      return result;
    } else {
      throw new Error('No Id');
    }
  } catch (err) {
    console.log(err);
  }
};
export const createGroup = async (uid, groupData = {}) => {
  try {
    if (uid && groupData && groupData.number) {
      const res = await firestoreCollection(COLLECTIONS.GROUPS)
        .doc(groupData.number)
        .set({
          groupInfo: groupData,
          teacher: uid,
          id: groupData.number,
        });
      return res;
    } else {
      throw 'No data for create group';
    }
  } catch (err) {
    console.log(err);
  }
};

export const getUserData = async uid => {
  try {
    if (uid) {
      const res = await firestoreCollection(COLLECTIONS.TEACHERS)
        .where('id', '==', uid)
        .get();
      const result = res.docs[0].data();
      return result;
    } else {
      throw new Error('No Id');
    }
  } catch (err) {
    console.log(err);
  }
};

export const checkInviteCode = async code => {
  try {
    if (code) {
      const res = await firestoreCollection(COLLECTIONS.INVITE_CODES)
        .where('code', '==', code)
        .get();
      const result = res.docs[0].data();
      if (result && result.active) {
        return result;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
export const getOldInviteCodes = async groupId => {
  try {
    if (groupId) {
      const res = await firestoreCollection(COLLECTIONS.INVITE_CODES)
        .where('group', '==', groupId)
        .get();
      if (res.docs.length) {
        const result = res.docs.map(doc => doc.data());
        const finishResult = result.filter(code => code.active);
        return finishResult;
      } else {
        throw 'codes-not-found';
      }
    } else {
      throw 'missing-group-id';
    }
  } catch (err) {
    throw err;
  }
};

export const createTest = async (
  {
    groupId = [],
    type = '',
    questions = [],
    teacher = '',
    date = '',
    time = '',
    description = '',
    name = '',
    files = {},
    showRight = false,
  },
  callback
) => {
  try {
    if (groupId && type && questions && teacher) {
      const res = await firestoreCollection(COLLECTIONS.TESTS).add({
        groupId,
        type,
        questions,
        teacher,
        date,
        time,
        description,
        name,
        files,
        showRight,
      });
      callback(res);
      return;
    } else {
      callback();
      throw 'missing-property';
    }
  } catch (err) {
    callback();
    throw err;
  }
};

export const updateTest = async (
  {
    id = '',
    groupId = [],
    type = '',
    questions = [],
    teacher = '',
    date = '',
    time = '',
    description = '',
    name = '',
    files = {},
    showRight = false,
  },
  callback
) => {
  try {
    const res = await firestoreCollection(COLLECTIONS.TESTS).doc(id).update({
      groupId,
      type,
      questions,
      teacher,
      date,
      time,
      description,
      name,
      files,
      showRight,
    });
    callback(res);
  } catch (err) {
    throw err;
  }
};

export const getMyTests = async id => {
  try {
    if (!id) {
      return null;
    }

    const res = await firestoreCollection(COLLECTIONS.TESTS)
      .where('teacher', '==', id)
      .get();

    const result = res.docs.map(el => {
      return {
        id: el.id,
        ...el.data(),
      };
    });

    return result;
  } catch (err) {
    throw err;
  }
};

export const uploadFile = async (
  { testId, fileType, name, blob },
  callback
) => {
  try {
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`/${fileType}/${testId}/${name}`);

    fileRef.put(blob).then(() => {
      callback();
    });
  } catch (err) {
    throw err;
  }
};

export const deleteFile = async (requestData, filePath, update, callback) => {
  try {
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(filePath);

    const res = fileRef.delete();

    console.log('RES!!!', res);
    if (update === 'tests') {
      updateTest(requestData, callback);
    } else {
      updateMaterial(requestData, callback);
    }

    return true;
  } catch (err) {
    callback();
    throw err;
  }
};

export const deleteTest = async (id, callback) => {
  try {
    const resDeleteTest = await firestoreCollection(COLLECTIONS.TESTS)
      .doc(id)
      .delete();

    // const resDeleteSuccessTests = await firestoreCollection(
    //   COLLECTIONS.SUCCESS_TESTS
    // )
    //   .where('id', '==', id)
    //   .delete();

    callback({
      resDeleteTest,
      // resDeleteSuccessTests,
    });

    return true;
  } catch (err) {
    throw err;
  }
};

export const deleteUser = async (id, callback) => {
  try {
    const res = await firestoreCollection(COLLECTIONS.STUDENTS).doc(id).update({
      group: firebase.firestore.FieldValue.delete(),
    });

    callback(res);
  } catch (err) {
    throw err;
  }
};

export const createMaterial = async (
  {
    groupId = [],
    type = '',
    author = '',
    tests = [],
    date = '',
    time = '',
    videoLink = '',
    description = '',
    name = '',
    files = {},
    showRight = false,
    posterName = '',
    markdown = '',
  },
  callback
) => {
  try {
    if (groupId && type && author) {
      const res = await firestoreCollection(COLLECTIONS.MATERIALS).add({
        groupId,
        type,
        author,
        date,
        time,
        videoLink,
        description,
        name,
        files,
        showRight,
        posterName,
        markdown,
      });
      callback(res);
      return;
    } else {
      callback();
      throw 'missing-property';
    }
  } catch (err) {
    callback();
    throw err;
  }
};

export const updateMaterial = async (
  {
    id = '',
    groupId = [],
    type = '',
    author = '',
    date = '',
    time = '',
    videoLink = '',
    description = '',
    name = '',
    files = {},
    showRight = false,
    posterName = '',
    markdown = '',
  },
  callback
) => {
  try {
    const res = await firestoreCollection(COLLECTIONS.MATERIALS)
      .doc(id)
      .update({
        groupId,
        type,
        author,
        date,
        time,
        videoLink,
        description,
        name,
        files,
        showRight,
        markdown,
        posterName,
      });
    callback(res);
  } catch (err) {
    throw err;
  }
};

export const deleteMaterial = async (id, callback) => {
  try {
    const resDeleteMaterial = await firestoreCollection(COLLECTIONS.MATERIALS)
      .doc(id)
      .delete();

    // const resDeleteSuccessTests = await firestoreCollection(
    //   COLLECTIONS.SUCCESS_TESTS
    // )
    //   .where('id', '==', id)
    //   .delete();

    callback({
      resDeleteMaterial,
      // resDeleteSuccessTests,
    });

    return true;
  } catch (err) {
    throw err;
  }
};

export const getMaterials = async () => {
  try {
    const res = await firestoreCollection(COLLECTIONS.MATERIALS).get();

    const result = res.docs.map(el => {
      return {
        id: el.id,
        ...el.data(),
      };
    });

    return result;
  } catch (err) {
    throw err;
  }
};

export const uploadMaterialFile = async (
  { materialId, fileType, name, blob },
  callback
) => {
  try {
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`/materials/${materialId}/${name}`);
    return fileRef.put(blob);
  } catch (err) {
    throw err;
  }
};

export const uploadMaterialPreview = async (
  { materialId, name, blob },
  callback
) => {
  try {
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(
      `/materials/${materialId}/preview/${name}`
    );

    fileRef.put(blob).then(() => {
      callback();
    });
  } catch (err) {
    throw err;
  }
};

export const getStudents = async () => {
  try {
    const res = await firestoreCollection(COLLECTIONS.STUDENTS).get();
    return res.docs;
  } catch (err) {
    console.log(err);
  }
};

export const getCards = async authorId => {
  try {
    const res = await firestoreCollection(COLLECTIONS.CARDS)
      .where('author', '==', authorId)
      .get();
    const result = res.docs.map(el => {
      return {
        id: el.id,
        ...el.data(),
      };
    });
    return result;
  } catch (err) {
    throw err;
  }
};
export const createCards = async ({
  author = '',
  groups = [],
  title = '',
  words = [],
}) => {
  try {
    const res = await firestoreCollection(COLLECTIONS.CARDS).add({
      author,
      groups,
      title,
      words,
    });

    return res; // id is here!!!
  } catch (err) {
    return err;
  }
};
export const updateCards = async ({
  id = '',
  author = '',
  groups = [],
  title = '',
  words = [],
}) => {
  try {
    const res = await firestoreCollection(COLLECTIONS.CARDS).doc(id).update({
      author,
      groups,
      title,
      words,
    });

    return true;
  } catch (err) {
    throw err;
  }
};
export const deleteCards = async id => {
  try {
    const res = await firestoreCollection(COLLECTIONS.CARDS).doc(id).delete();
    return true;
  } catch (err) {
    throw err;
  }
};
