import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import HeaderView from './HeaderView';

const HeaderContainer = props => {
  const [data, setData] = useState(null);

  useEffect(() => {}, []);

  return <HeaderView userData={props.userData} />;
};

const mapStateToProps = state => ({
  userData: state.user.userData,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
