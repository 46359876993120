import React from 'react';

const Container = props => {
  const styles = {
    container: {
      width: '1240px',
    },
  };
  return <div style={styles.container}>{props.children}</div>;
};

export default Container;
