import React, { useState } from 'react';
import './style.css';
import Tabs from '../../components/tabs/Tabs';
import UsersTableContainer from '../usersTable/UsersTableContainer';
import AddStudentToGroup from '../../components/addStudentToGroup/AddStudentToGroup';
import Loader from '../../components/loader/Loader';
import { NavLink } from 'react-router-dom';

const GroupsView = props => {
  if (!props.groups) {
    return <Loader />;
  }

  const groupsToTabs = props.groups.map(group => {
    return {
      id: group.id,
      name: group.groupInfo.name,
    };
  });
  return (
    <div>
      <div className="groups-header">
        <Tabs tabs={groupsToTabs} handleChangeGroup={props.setGroupId} />
        <NavLink to="/addgroup" className="button-add-group">
          Новая группа
        </NavLink>
      </div>
      {props.activeGroup && (
        <div className="group-info">
          <div className="group-info__title">
            {props.activeGroup.groupInfo.name}
          </div>
          <div className="group-info__description">
            <div className="group-info__level">
              {props.activeGroup.groupInfo.level}
            </div>
            <div className="group-info__students">
              {props.students.length} студентов
              <div
                onClick={() => props.setAddStudentOpen(old => !old)}
                className="group-info__addStudent">
                <img
                  className="group-info__addStudent__icon"
                  src={require('../../assets/pictures/plus.svg')}
                  alt="Новая группа"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.addStudentOpen ? (
        <AddStudentToGroup groupId={props.groupId} />
      ) : (
        <UsersTableContainer
          students={props.students}
          groupId={props.groupId}
          deleteStudent={props.deleteStudent}
        />
      )}
    </div>
  );
};

export default GroupsView;
