import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';

import MaterialsView from './MaterialsView';
import {
  getGroups,
  createMaterial,
  getMaterials,
  uploadMaterialFile,
  updateMaterial,
  deleteFile,
  deleteMaterial,
  uploadMaterialPreview,
} from '../../core/firebase';

const INITIALS = {
  newMaterialInfo: {
    name: '',
    description: '',
    markdown: '',
    date: '',
    time: '',
    videoLink: '',
    files: {},
  },
};

const MaterialsContainer = props => {
  const [materialsList, setMaterialsList] = useState([]);
  const [materialsLoading, setMaterialsLoading] = useState(true);
  const [groupIdMap, setGroupIdMap] = useState(new Map());

  const [editingMode, setEditingMode] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);
  const [groupsList, setGroupsList] = useState([]);

  const getMaterialsFromFirebase = () => {
    getMaterials().then(res => {
      console.log('Load Materials!!!', res);
      setMaterialsList(res);
      setMaterialsLoading(false);
    });
  };

  useEffect(() => {
    getGroups(props.state.user.userData.id).then(res => {
      setGroupsList(res);
    });

    getMaterialsFromFirebase();
  }, []);

  const [poster, setPoster] = useState(null);
  const [file, setFile] = useState(null);
  const [newMaterialInfo, setNewMaterialInfo] = useState(
    INITIALS.newMaterialInfo
  );

  const openMaterialToEdit = id => {
    const material = materialsList.find(item => item.id === id);

    console.log(material);

    if (material) {
      setNewMaterialInfo({
        id: material.id,
        name: material.name,
        date: material.date,
        time: material.time,
        videoLink: material.videoLink,
        author: material.author,
        type: material.type,
        files: material.files,
        description: material.description,
        posterName: material.posterName,
        markdown: material.markdown,
      });

      let groupsIds = new Map();

      for (let i = 0; i < material.groupId.length; i++) {
        groupsIds.set(material.groupId[i], material.groupId[i]);
      }

      setGroupIdMap(groupsIds);

      setEditingMode(material.id);
    }
  };

  const handleShowRightChange = value => {
    setNewMaterialInfo({
      ...newMaterialInfo,
      showRight: value,
    });
  };

  const handleGroupsChange = (type, isActive) => {
    if (isActive) {
      if (!groupIdMap.has(type)) {
        groupIdMap.set(type, type);
      }
    } else {
      groupIdMap.delete(type);
    }
  };

  const handleMaterialNameChange = e => {
    setNewMaterialInfo({
      ...newMaterialInfo,
      name: e.target.value,
    });
  };
  const handleMaterialMarkdownChange = markup => {
    setNewMaterialInfo({
      ...newMaterialInfo,
      markdown: markup,
    });
  };
  const handleMaterialDescriptionChange = e => {
    setNewMaterialInfo({
      ...newMaterialInfo,
      description: e,
    });
  };
  const handleMaterialStartDateChange = e => {
    setNewMaterialInfo({
      ...newMaterialInfo,
      date: e.target.value,
    });
  };
  const handleMaterialStartTimeChange = e => {
    setNewMaterialInfo({
      ...newMaterialInfo,
      time: e.target.value,
    });
  };
  const handleMaterialVideoLinkChange = e => {
    setNewMaterialInfo({
      ...newMaterialInfo,
      videoLink: e.target.value,
    });
  };
  const handleMaterialTypeChange = type => {
    handleMaterialDescriptionChange('');
    setNewMaterialInfo({
      ...newMaterialInfo,
      type,
    });
  };

  const saveNewMaterial = async () => {
    setSaveLoading(true);

    let requestData = {
      ...newMaterialInfo,
      groupId: Array.from(groupIdMap.keys()),
      author: props.state.user.userData.id,
    };
    console.log('requestData', requestData);
    if (file) {
      requestData.files = {
        [newMaterialInfo.type]: file.name,
      };
    }

    if (poster) {
      requestData.posterName = poster.name;
    }

    if (!editingMode) {
      // Режим добавления нового теста
      createMaterial(requestData, res => {
        if (res) {
          if (file) {
            uploadMaterialFile(
              {
                materialId: res.id,
                fileType: newMaterialInfo.type,
                name: file.name,
                blob: file.blob,
              },
              () => {
                getMaterialsFromFirebase();
                setSaveLoading(false);
              }
            );
          }

          if (poster) {
            uploadMaterialPreview(
              {
                materialId: res.id,
                name: poster.name,
                blob: poster.blob,
              },
              () => {
                getMaterialsFromFirebase();
                setSaveLoading(false);
              }
            );
          }

          setEditingMode(res.id);
          setNewMaterialInfo({
            ...newMaterialInfo,
            id: res.id,
          });
          store.addNotification({
            title: 'Успешно',
            message: 'Материал упешно добавлен',
            type: 'success',
            container: 'bottom-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });

          if (!poster && !file) {
            getMaterialsFromFirebase();
            setSaveLoading(false);
          }
        } else {
          setSaveLoading(false);
          store.addNotification({
            title: 'Ошибка',
            message: 'Не удалось добавить материал',
            type: 'danger',
            container: 'bottom-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        }
      });
    } else {
      // Режим редактирования
      updateMaterial(
        {
          id: editingMode,
          ...requestData,
        },
        async res => {
          if (file) {
            await uploadMaterialFile({
              materialId: editingMode,
              fileType: newMaterialInfo.type,
              name: file.name,
              blob: file.blob,
            });

            setNewMaterialInfo({
              ...newMaterialInfo,
              files: {
                ...newMaterialInfo.files,
                [newMaterialInfo.type]: file.name,
              },
            });
          }

          if (poster) {
            await uploadMaterialFile({
              materialId: editingMode,
              name: poster.name,
              blob: poster.blob,
            });

            setNewMaterialInfo({
              ...newMaterialInfo,
              posterName: poster.name,
            });
          }

          store.addNotification({
            title: 'Успешно',
            message: 'Материал упешно обновлен',
            type: 'success',
            container: 'bottom-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });

          setSaveLoading(false);
          getMaterialsFromFirebase();
        }
      );
    }
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    const name = file.name;

    const blob = new Blob([file]);

    setFile({
      name,
      blob,
    });
  };

  const handlePosterChange = e => {
    const file = e.target.files[0];
    const name = file.name;

    const blob = new Blob([file]);

    setPoster({
      name,
      blob,
    });
  };

  const deleteMaterialFile = () => {
    let filePath = `materials/${editingMode}/${
      newMaterialInfo.files[newMaterialInfo.type]
    }`;

    setSaveLoading(true);

    let requestData = {
      ...newMaterialInfo,
      groupId: Array.from(groupIdMap.keys()),
      author: props.state.user.userData.id,
    };

    requestData.files = {
      ...requestData.files,
      [newMaterialInfo.type]: null,
    };

    deleteFile(
      {
        id: editingMode,
        ...requestData,
      },
      filePath,
      '',
      () => {
        store.addNotification({
          title: 'Успешно',
          message: 'Файл упешно удален',
          type: 'success',
          container: 'bottom-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });

        setNewMaterialInfo({
          ...newMaterialInfo,
          files: {
            ...newMaterialInfo.files,
            [newMaterialInfo.type]: null,
          },
        });

        setSaveLoading(false);
        getMaterialsFromFirebase();
      }
    );
  };

  const deleteMaterialPoster = () => {
    const filePath = `materials/${editingMode}/preview/${newMaterialInfo.posterName}`;

    setSaveLoading(true);

    let requestData = {
      ...newMaterialInfo,
      groupId: Array.from(groupIdMap.keys()),
      author: props.state.user.userData.id,
    };

    requestData.posterName = null;

    deleteFile(
      {
        id: editingMode,
        ...requestData,
      },
      filePath,
      '',
      () => {
        store.addNotification({
          title: 'Успешно',
          message: 'Файл упешно удален',
          type: 'success',
          container: 'bottom-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });

        setNewMaterialInfo({
          ...newMaterialInfo,
          posterName: null,
        });

        setSaveLoading(false);
        getMaterialsFromFirebase();
      }
    );
  };

  const deleteMaterialFromFirebase = () => {
    const deleteTestConfirmation = window.confirm(
      'Вы действительно хотите удалить этот материал?'
    );

    if (deleteTestConfirmation) {
      deleteMaterial(newMaterialInfo.id, () => {
        store.addNotification({
          title: 'Успешно',
          message: 'Материал упешно удален',
          type: 'success',
          container: 'bottom-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        getMaterialsFromFirebase();
        clearState();
      });
    }
  };

  const clearState = () => {
    setGroupIdMap(new Map());
    setEditingMode(false);
    setFile(null);
    setPoster(null);
    setNewMaterialInfo(INITIALS.newMaterialInfo);
  };

  return (
    <MaterialsView
      saveLoading={saveLoading}
      groupsList={groupsList}
      newMaterialInfo={newMaterialInfo}
      handleMaterialNameChange={handleMaterialNameChange}
      handleMaterialMarkdownChange={handleMaterialMarkdownChange}
      handleMaterialDescriptionChange={handleMaterialDescriptionChange}
      handleMaterialStartDateChange={handleMaterialStartDateChange}
      handleMaterialStartTimeChange={handleMaterialStartTimeChange}
      handleMaterialVideoLinkChange={handleMaterialVideoLinkChange}
      handleMaterialTypeChange={handleMaterialTypeChange}
      handleGroupsChange={handleGroupsChange}
      handleFileChange={handleFileChange}
      materialsLoading={materialsLoading}
      materialsList={materialsList}
      openMaterialToEdit={openMaterialToEdit}
      groupIdMap={groupIdMap}
      deleteMaterialFile={deleteMaterialFile}
      deleteMaterialFromFirebase={deleteMaterialFromFirebase}
      clearState={clearState}
      handlePosterChange={handlePosterChange}
      poster={poster}
      deleteMaterialPoster={deleteMaterialPoster}
      saveNewMaterial={saveNewMaterial}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialsContainer);
