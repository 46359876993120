import styled from 'styled-components';

export const Root = styled.div`
  border: 1px solid #efefef;
  background-color: white;
  border-radius: 4px;
  font-size: 16px
  line-height: 20px;
  min-height: 450px;
  font-weight: 300;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
`;

export const EditorContainer = styled.div`
  border-top: 1px solid #ddd
  padding: 15px;
  margin-top: 10px;
  min-height: 293px;
  cursor: text;

  img {
    height: 178px
    object-fit: cover;
    width: 335px;
  }
`;

export const Controls = styled.div`
  margin-bottom: 5px;
  user-select: none;
`;

export const Button = styled.span`
  color: ${({ active }) => (active ? '#5890ff' : '#999')};
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  font-size: 18px;
`;
