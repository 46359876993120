import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import { logOut } from '../../store/reducers/user-reducer';

import UserBlockView from './UserBlockView';
const UserBlockContainer = props => {
  const [displayUserMenu, setDisplayUserMenu] = useState(false);

  console.log('ptops', props.userData);

  const openUserMenu = () => {
    setDisplayUserMenu(!displayUserMenu);
  };

  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        props.logOut();
        console.log('logged out');
      })
      .catch(function (error) {
        console.log('logged error', error);
        store.addNotification({
          title: 'Ошибка',
          message: error.message,
          type: 'danger',
          container: 'bottom-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });
  };

  return (
    <UserBlockView
      displayUserMenu={displayUserMenu}
      openUserMenu={openUserMenu}
      userData={props.userData}
      logOut={logOut}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(logOut()),
});

export default connect('', mapDispatchToProps)(UserBlockContainer);
