import React from 'react';
import './style.css';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '../../assets/icons/dashboard';
import GroupsIcon from '../../assets/icons/groups';
import MaterialsIcon from '../../assets/icons/materials';
import TasksIcon from '../../assets/icons/tasks';
import CardsIcon from '../../assets/icons/cards';

const Menu = () => {
  return (
    <div className="menu-container">
      <NavLink
        activeClassName="menu-item_active"
        to="/groups"
        className="menu-item">
        <GroupsIcon />
        <div className="menu-item__text">Группы</div>
      </NavLink>
      <NavLink
        activeClassName="menu-item_active"
        to="/materials"
        className="menu-item">
        <MaterialsIcon />
        <div className="menu-item__text">Материалы</div>
      </NavLink>
      <NavLink
        activeClassName="menu-item_active"
        to="/cards"
        className="menu-item">
        <CardsIcon />
        <div className="menu-item__text">Наборы слов</div>
      </NavLink>
      <NavLink
        activeClassName="menu-item_active"
        to="/tasks"
        className="menu-item">
        <TasksIcon />
        <div className="menu-item__text">Задания</div>
      </NavLink>
      <NavLink
        activeClassName="menu-item_active"
        to="/dashboard"
        className="menu-item">
        <DashboardIcon />

        <div className="menu-item__text">Дашбоард</div>
      </NavLink>
    </div>
  );
};

export default Menu;
