import React from 'react';
import Dropdown from 'react-dropdown';

import '../addGroup/dropdown.css';
import './style.css';
import Button from '../../components/button';
import Loader from '../../components/loader/Loader';
import TestType from '../../components/create-test/test-type';
import EditorView from '../../components/editor/Editor';

const renderGroups = props =>
  props.groupsList.map(item => (
    <TestType
      text={item.groupInfo.name}
      type={item.id}
      active={props.groupIdMap.has(item.id)}
      onStateChange={(type, isActive) => {
        props.handleGroupsChange(type, isActive);
      }}
    />
  ));

const renderMaterialsList = props =>
  props.materialsList.map(item => (
    <div
      className="test-list-container"
      onClick={() => {
        props.openMaterialToEdit(item.id);
      }}>
      {item.name}
    </div>
  ));

const MaterialsView = props => {
  return (
    <div className="tests-container">
      <div className="tests-list-container">
        {props.materialsList.length === 0 && (
          <div className="tests-list-empty-container">
            <img
              src={require('../../assets/pictures/tests-list.svg')}
              alt="Список материалов пуст"
            />
            {props.materialsLoading && <Loader />}
            {!props.materialsLoading && (
              <div className="tests-list-empty-sign">
                На данный момент нет материалов
              </div>
            )}
          </div>
        )}
        {props.materialsList.length > 0 && (
          <>
            <div className="add-new-test-button-container">
              <Button
                small
                textButton="Добавить новый материал"
                onClick={props.clearState}
              />
            </div>
            {renderMaterialsList(props)}
          </>
        )}
      </div>
      <div className="new-test-container">
        <div className="new-test-header">
          <div className="new-test-header-left">
            <div className="new-test-title-container">
              <input
                type="text"
                class="new-test-title"
                placeholder="Новый материал"
                onChange={props.handleMaterialNameChange}
                value={props.newMaterialInfo.name}
              />
            </div>
            <div className="new-test-description-container">
              {props.newMaterialInfo.type === 'standart' ? (
                <EditorView
                  value={props.newMaterialInfo.markdown}
                  onChange={props.handleMaterialMarkdownChange}
                />
              ) : (
                <textarea
                  class="new-test-description"
                  placeholder="Описание"
                  onChange={e =>
                    props.handleMaterialDescriptionChange(e.currentTarget.value)
                  }
                  value={props.newMaterialInfo.description}
                />
              )}
              {/* <Editor
                editorState={editorState}
                onChange={editor => setEditorState(editor)}
              /> */}
            </div>
          </div>
          <div className="new-test-header-right">
            {props.newMaterialInfo.id && (
              <div className="new-test-cancel-container">
                <Button
                  type="secondary"
                  small
                  textButton="Удалить материал"
                  disabled={props.saveLoading}
                  onClick={props.deleteMaterialFromFirebase}
                />
              </div>
            )}
            <div className="new-test-save-container">
              <Button
                small
                textButton="Сохранить"
                onClick={props.saveNewMaterial}
                disabled={props.saveLoading}
              />
            </div>
          </div>
        </div>

        <div className="new-test-content-container">
          <div className="new-test-datetime-container">
            <div className="new-test-date-container">
              <label for="new-test-date" className="new-test-date-title">
                Дата начала
              </label>
              <input
                id="new-test-date"
                type="date"
                className="new-test-date"
                onChange={props.handleMaterialStartDateChange}
                value={props.newMaterialInfo.date}
              />
            </div>
            <div className="new-test-time-container">
              <label for="new-test-time" className="new-test-time-title">
                Время начала
              </label>
              <input
                id="new-test-time"
                type="time"
                className="new-test-time"
                onChange={props.handleMaterialStartTimeChange}
                value={props.newMaterialInfo.time}
              />
            </div>
          </div>
        </div>

        <div className="new-test-type-container">
          <div className="new-test-type-title">Постер</div>
          <div className="new-test-type-list-container">
            <div className="new-test-file-container">
              {props.newMaterialInfo.posterName && (
                <div className="new-test-file-uploaded-container">
                  {props.newMaterialInfo.posterName}
                  <div className="new-test-file-delete-container">
                    <Button
                      small
                      type="secondary"
                      textButton="Удалить файл"
                      onClick={props.deleteMaterialPoster}
                    />
                  </div>
                </div>
              )}
              {!props.newMaterialInfo.posterName && (
                <input
                  type="file"
                  className="new-test-file"
                  onChange={props.handlePosterChange}
                />
              )}
            </div>
          </div>
        </div>

        <div className="new-test-type-container">
          <div className="new-test-type-title">Тип материала</div>
          <div className="new-test-type-list-container">
            <TestType
              text="Стандартный"
              type="standart"
              active={props.newMaterialInfo.type === 'standart'}
              onStateChange={type => {
                props.handleMaterialTypeChange(type);
              }}
            />
            <TestType
              text="Аудио"
              type="audio"
              active={props.newMaterialInfo.type === 'audio'}
              onStateChange={type => {
                props.handleMaterialTypeChange(type);
              }}
            />
            <TestType
              text="Видео"
              type="video"
              active={props.newMaterialInfo.type === 'video'}
              onStateChange={type => {
                props.handleMaterialTypeChange(type);
              }}
            />
            <TestType
              text="Файл (pdf)"
              type="fileDoc"
              active={props.newMaterialInfo.type === 'fileDoc'}
              onStateChange={type => {
                props.handleMaterialTypeChange(type);
              }}
            />
          </div>
        </div>

        {props.newMaterialInfo.type === 'audio' && (
          <div className="new-test-type-container">
            <div className="new-test-type-title">Загрузите аудио файл</div>
            <div className="new-test-type-list-container">
              <div className="new-test-file-container">
                {props.newMaterialInfo.files &&
                  props.newMaterialInfo.files[props.newMaterialInfo.type] && (
                    <div className="new-test-file-uploaded-container">
                      {props.newMaterialInfo.files[props.newMaterialInfo.type]}
                      <div className="new-test-file-delete-container">
                        <Button
                          small
                          type="secondary"
                          textButton="Удалить файл"
                          onClick={props.deleteMaterialFile}
                        />
                      </div>
                    </div>
                  )}
                {props.newMaterialInfo.files &&
                  !props.newMaterialInfo.files[props.newMaterialInfo.type] && (
                    <input
                      type="file"
                      className="new-test-file"
                      onChange={props.handleFileChange}
                    />
                  )}
              </div>
            </div>
          </div>
        )}

        {props.newMaterialInfo.type === 'fileDoc' && (
          <div className="new-test-type-container">
            <div className="new-test-type-title">Загрузите PDF файл</div>
            <div className="new-test-type-list-container">
              <div className="new-test-file-container">
                {props.newMaterialInfo.files &&
                  props.newMaterialInfo.files[props.newMaterialInfo.type] && (
                    <div className="new-test-file-uploaded-container">
                      {props.newMaterialInfo.files[props.newMaterialInfo.type]}
                      <div className="new-test-file-delete-container">
                        <Button
                          small
                          type="secondary"
                          textButton="Удалить файл"
                          onClick={props.deleteMaterialFile}
                        />
                      </div>
                    </div>
                  )}
                {props.newMaterialInfo.files &&
                  !props.newMaterialInfo.files[props.newMaterialInfo.type] && (
                    <input
                      type="file"
                      className="new-test-file"
                      onChange={props.handleFileChange}
                    />
                  )}
              </div>
            </div>
          </div>
        )}

        {props.newMaterialInfo.type === 'video' && (
          <div className="new-test-content-container">
            <div className="new-test-date-container">
              <label for="material-video-link" className="new-test-date-title">
                Ссылка на видео
              </label>
              <input
                id="material-video-link"
                type="text"
                placeholder="https://www.youtube.com/watch?v=..."
                className="new-test-title"
                onChange={props.handleMaterialVideoLinkChange}
                value={props.newMaterialInfo.videoLink}
              />
            </div>
          </div>
        )}

        <div className="new-test-type-container">
          <div className="new-test-type-title">Группы</div>
          <div className="new-test-type-list-container">
            {renderGroups(props)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialsView;
