import React, { useEffect } from 'react';
import './style.css';
import Input from '../../components/input';
import Button from '../../components/button';
import Dropdown from 'react-dropdown';
import './dropdown.css';

const AddGroupView = props => {
  const options = [
    'Elementary',
    'Pre-Intermediate',
    'Intermediate',
    'Upper-Intermediate',
    'Advanced',
  ];

  useEffect(() => {
    props.setNewGroupInfo(old => {
      return {
        ...old,
        level: options[0],
      };
    });
  }, []);

  return (
    <div className="addGroup-container">
      <div className="addGroup-title">Создание новой группы</div>

      <Input
        value={props.newGroupInfo.number}
        onChange={text =>
          props.setNewGroupInfo(old => {
            return {
              ...old,
              number: text,
            };
          })
        }
        placeholder="Введите номер группы"
      />
      <Input
        value={props.newGroupInfo.name}
        onChange={text =>
          props.setNewGroupInfo(old => {
            return {
              ...old,
              name: text,
            };
          })
        }
        placeholder="Введите название группы"
      />
      <Dropdown
        options={options}
        onChange={({ value }) => {
          props.setNewGroupInfo(old => {
            return {
              ...old,
              level: value,
            };
          });
        }}
        value={props.newGroupInfo.level}
        placeholder="Select an option"
      />
      <Button
        type="primary"
        onClick={props.handleCreateGroup}
        loading={props.loading}
        textButton="Создать"
      />
    </div>
  );
};

export default AddGroupView;
