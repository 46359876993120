import React from 'react';

const MaterialsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 7.31763H19C20.1046 7.31763 21 8.21306 21 9.31763V17.3176C21 18.4222 20.1046 19.3176 19 19.3176H5C3.89543 19.3176 3 18.4222 3 17.3176V7.31763Z"
      stroke="#A0A5B9"
      strokeWidth="1.5"
    />
    <path
      d="M3 7.31763V4.31763C3 3.76534 3.44772 3.31763 4 3.31763H8C9.10457 3.31763 10 4.21306 10 5.31763V7.31763"
      stroke="#A0A5B9"
      strokeWidth="1.5"
    />
    <path
      d="M6.66016 11.882H12.6602"
      stroke="#A0A5B9"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6.66016 14.7532H17.5938"
      stroke="#A0A5B9"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default MaterialsIcon;

