import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import AddGroupView from './AddGroupView';
import { createGroup, updateUserData } from '../../core/firebase';

const AddGroupContainer = props => {
  const [loading, setLoading] = useState(false);
  const [newGroupInfo, setNewGroupInfo] = useState({
    number: '',
    name: '',
    level: null,
  });
  const handleCreateGroup = async () => {
    setLoading(true);
    try {
      if (
        newGroupInfo.name !== '' &&
        newGroupInfo.number !== '' &&
        newGroupInfo.level
      ) {
        const res = await createGroup(props.userData.id, newGroupInfo);
        alert('Группа добавлена');
      } else {
        throw new Error('No data');
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <AddGroupView
      newGroupInfo={newGroupInfo}
      loading={loading}
      setNewGroupInfo={setNewGroupInfo}
      handleCreateGroup={handleCreateGroup}
    />
  );
};

const mapStateToProps = state => ({
  userData: state.user.userData,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupContainer);
