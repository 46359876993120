const COLLECTIONS = {
  TEACHERS: 'teachers',
  STUDENTS: 'students',
  INVITE_CODES: 'inviteCodes',
  GROUPS: 'groups',
  TESTS: 'tests',
  MATERIALS: 'materials',
  SUCCESS_TESTS: 'successTests',
  CARDS: 'cards',
};

export default COLLECTIONS;
