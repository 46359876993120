import React from 'react';
import Dropdown from 'react-dropdown';

import '../addGroup/dropdown.css';
import './style.css';
import Button from '../../components/button';
import Loader from '../../components/loader/Loader';
import TestType from '../../components/create-test/test-type';
import AddQuestion from '../../components/create-test/add-question';

const renderQuestions = props => {
  return props.newTestQuestions.map((item, index) => (
    <AddQuestion
      id={index}
      key={`answer-${index}`}
      question={props.newTestQuestions[index]}
      answers={props.newTestQuestions[index].answers}
      removeHandle={props.removeQuestionHandle}
      handleQuestionTextChange={props.handleQuestionTextChange}
      handleAddAnswer={props.handleAddAnswer}
      handleAnswerTextChange={props.handleAnswerTextChange}
      handleAnswerRightChange={props.handleAnswerRightChange}
    />
  ));
};

const renderGroups = props =>
  props.groupsList.map(item => (
    <TestType
      text={item.groupInfo.name}
      type={item.id}
      active={props.groupIdMap.has(item.id)}
      onStateChange={(type, isActive) => {
        props.handleGroupsChange(type, isActive);
      }}
    />
  ));

const renderTestsList = props =>
  props.myTests.map(item => (
    <div
      className="test-list-container"
      onClick={() => {
        props.openTestToEdit(item.id);
      }}>
      {item.name}
    </div>
  ));

const TasksView = props => {
  console.log('tasks', props);
  return (
    <div className="tests-container">
      <div className="tests-list-container">
        {props.myTests.length === 0 && (
          <div className="tests-list-empty-container">
            <img
              src={require('../../assets/pictures/tests-list.svg')}
              alt="Список тестов пуст"
            />
            {props.myTestsLoading && <Loader />}
            {!props.myTestsLoading && (
              <div className="tests-list-empty-sign">
                У Вас еще нет сохраненных тестов
              </div>
            )}
          </div>
        )}
        {props.myTests.length > 0 && (
          <>
            <div className="add-new-test-button-container">
              <Button
                small
                textButton="Добавить новый тест"
                onClick={props.clearState}
              />
            </div>
            {renderTestsList(props)}
          </>
        )}
      </div>

      <div className="new-test-container">
        <div className="new-test-header">
          <div className="new-test-header-left">
            <div className="new-test-title-container">
              <input
                type="text"
                class="new-test-title"
                placeholder="Новый тест"
                onChange={props.handleTestNameChange}
                value={props.newTestInfo.name}
              />
            </div>
            <div className="new-test-description-container">
              <textarea
                class="new-test-description"
                placeholder="Описание"
                onChange={props.handleTestDescriptionChange}
                value={props.newTestInfo.description}
              />
            </div>
          </div>
          <div className="new-test-header-right">
            {props.newTestInfo.id && (
              <div className="new-test-cancel-container">
                <Button
                  type="secondary"
                  small
                  textButton="Удалить тест"
                  disabled={props.saveLoading}
                  onClick={props.deleteTestFromFirebase}
                />
              </div>
            )}
            <div className="new-test-save-container">
              <Button
                small
                textButton="Сохранить"
                onClick={props.saveNewTest}
                disabled={props.saveLoading}
              />
            </div>
          </div>
        </div>

        <div className="new-test-content-container">
          <div className="new-test-datetime-container">
            <div className="new-test-date-container">
              <label for="new-test-date" className="new-test-date-title">
                Дата начала
              </label>
              <input
                id="new-test-date"
                type="date"
                className="new-test-date"
                onChange={props.handleTestStartDateChange}
                value={props.newTestInfo.date}
              />
            </div>
            <div className="new-test-time-container">
              <label for="new-test-time" className="new-test-time-title">
                Время начала
              </label>
              <input
                id="new-test-time"
                type="time"
                className="new-test-time"
                onChange={props.handleTestStartTimeChange}
                value={props.newTestInfo.time}
              />
            </div>
          </div>
        </div>

        <div className="new-test-type-container">
          <div className="new-test-type-title">
            Показывать правильные ответы
          </div>
          <div className="new-test-type-list-container-stupid-paddings">
            <Dropdown
              options={props.SHOW_RIGHT_OPTIONS}
              onChange={({ value }) => {
                props.handleShowRightChange(value);
              }}
              value={props.newTestInfo.showRight}
              placeholder="Select an option"
            />
          </div>
        </div>

        <div className="new-test-type-container">
          <div className="new-test-type-title">Группы</div>
          <div className="new-test-type-list-container">
            {renderGroups(props)}
          </div>
        </div>

        <div className="new-test-type-container">
          <div className="new-test-type-title">Тип теста</div>
          <div className="new-test-type-list-container">
            <TestType
              text="Грамматика"
              type="grammar"
              active={props.newTestInfo.type === 'grammar'}
              onStateChange={type => {
                props.handleTestTypeChange(type);
              }}
            />
            <TestType
              text="Лексика"
              type="vocabulary"
              active={props.newTestInfo.type === 'vocabulary'}
              onStateChange={type => {
                props.handleTestTypeChange(type);
              }}
            />
            <TestType
              text="Аудирование"
              type="listening"
              active={props.newTestInfo.type === 'listening'}
              onStateChange={type => {
                props.handleTestTypeChange(type);
              }}
            />
            <TestType
              text="Словарный диктант"
              type="dictation"
              active={props.newTestInfo.type === 'dictation'}
              onStateChange={type => {
                props.handleTestTypeChange(type);
              }}
            />
            <TestType
              text="Перевод"
              type="translation"
              active={props.newTestInfo.type === 'translation'}
              onStateChange={type => {
                props.handleTestTypeChange(type);
              }}
            />
            <TestType
              text="Фонетика"
              type="phonetics"
              active={props.newTestInfo.type === 'phonetics'}
              onStateChange={type => {
                props.handleTestTypeChange(type);
              }}
            />
          </div>
        </div>

        {props.newTestInfo.type === 'listening' && (
          <div className="new-test-type-container">
            <div className="new-test-type-title">
              Загрузите файл для аудирования
            </div>
            <div className="new-test-type-list-container">
              <div className="new-test-file-container">
                {props.newTestInfo.files &&
                  props.newTestInfo.files[props.newTestInfo.type] && (
                    <div className="new-test-file-uploaded-container">
                      {props.newTestInfo.files[props.newTestInfo.type]}
                      <div className="new-test-file-delete-container">
                        <Button
                          small
                          type="secondary"
                          textButton="Удалить файл"
                          onClick={props.deleteTestFile}
                        />
                      </div>
                    </div>
                  )}
                {props.newTestInfo.files &&
                  !props.newTestInfo.files[props.newTestInfo.type] && (
                    <input
                      type="file"
                      className="new-test-file"
                      onChange={props.handleFileChange}
                    />
                  )}
              </div>
            </div>
          </div>
        )}

        <div className="new-test-questions-container">
          <div className="new-test-questions-header">
            <div className="new-test-questions-header-left">
              <div className="new-test-questions-title">Вопросы</div>
            </div>
            <div
              onClick={props.addQuestionHandle}
              className="group-info__addQuestion">
              <img
                className="group-info__addQuestion__icon"
                src={require('../../assets/pictures/plus.svg')}
                alt="Новый вопрос"
              />
            </div>
          </div>
          {renderQuestions(props)}
        </div>
      </div>
    </div>
  );
};

export default TasksView;
