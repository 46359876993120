import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';

import CardsView from './CardsView';
import {
  getGroups,
  getCards,
  createCards,
  updateCards,
  deleteCards,
} from '../../core/firebase';

const INITIALS = {
  newCardInfo: {
    title: '',
  },
  words: [
    {
      number: 1,
      word: '',
      translate: '',
    },
  ],
};

let wordsNumber = INITIALS.words.length;

const CardsContainer = props => {
  const [cardsList, setCardsList] = useState([]);
  const [cardsListLoading, setCardsListLoading] = useState(false);
  const [newCardInfo, setNewCardInfo] = useState(INITIALS.newCardInfo);
  const [saveLoading, setSaveLoading] = useState(false);
  const [groupsList, setGroupsList] = useState([]);
  const [groupIdMap, setGroupIdMap] = useState(new Map());
  const [words, setWords] = useState(INITIALS.words);
  const [editingMode, setEditingMode] = useState(null);

  const getMyCardsFromFirebase = () => {
    setCardsListLoading(true);
    getCards(props.state.user.userData.id).then(res => {
      setCardsList(res);
      setCardsListLoading(false);
    });
  };

  useEffect(() => {
    getGroups(props.state.user.userData.id).then(res => {
      setGroupsList(res);
    });

    getMyCardsFromFirebase();
  }, []);

  const openCardsToEdit = id => {
    const cards = cardsList.find(item => item.id === id);

    if (cards) {
      setNewCardInfo({
        id: cards.id,
        title: cards.title,
      });

      setWords(cards.words);

      let groupsIds = new Map();

      for (let i = 0; i < cards.groups.length; i++) {
        groupsIds.set(cards.groups[i], cards.groups[i]);
      }

      setGroupIdMap(groupsIds);

      setEditingMode(cards.id);
    }
  };

  const handleCardTitleChange = e => {
    setNewCardInfo({
      ...newCardInfo,
      title: e.target.value,
    });
  };

  const handleGroupsChange = (type, isActive) => {
    if (isActive) {
      if (!groupIdMap.has(type)) {
        groupIdMap.set(type, type);
      }
    } else {
      groupIdMap.delete(type);
    }
  };

  const saveNewCards = async () => {
    setSaveLoading(true);

    const requestData = {
      ...newCardInfo,
      author: props.state.user.userData.id,
      groups: Array.from(groupIdMap.keys()),
      words,
    };

    if (editingMode) {
      // Режим редактирования
      try {
        await updateCards(requestData);

        store.addNotification({
          title: 'Успешно',
          message: 'Набор упешно обновлен',
          type: 'success',
          container: 'bottom-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });

        setSaveLoading(false);
      } catch (err) {
        store.addNotification({
          title: 'Не успешно',
          message: 'Не удалось обновить набор',
          type: 'danger',
          container: 'bottom-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });

        setSaveLoading(false);
      }
    } else {
      // Режим создания нового набора слов
      const res = await createCards(requestData);

      if (res.id) {
        store.addNotification({
          title: 'Успешно',
          message: 'Набор упешно добавлен',
          type: 'success',
          container: 'bottom-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });

        getMyCardsFromFirebase();

        setEditingMode(res.id);
        setSaveLoading(false);
      } else {
        store.addNotification({
          title: 'Не успешно',
          message: 'Не удалось сохранить, попробуйте позже',
          type: 'danger',
          container: 'bottom-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        setSaveLoading(false);
      }
    }
  };

  const handleAddWord = () => {
    wordsNumber += 1;
    setWords([
      ...words,
      {
        number: wordsNumber,
        word: '',
        translate: '',
      },
    ]);
  };

  const handleRemoveWord = index => {
    let oldWords = words.slice(0);
    oldWords.splice(parseInt(index, 10), 1);
    setWords(oldWords);
  };

  const onWordChange = (e, index) => {
    let oldWords = words.slice(0);
    oldWords[index].word = e.target.value;
    setWords(oldWords);
  };
  const onTranslateChange = (e, index) => {
    let oldWords = words.slice(0);
    oldWords[index].translate = e.target.value;
    setWords(oldWords);
  };

  const clearState = () => {
    setGroupIdMap(new Map());
    setEditingMode(null);
    setNewCardInfo(INITIALS.newCardInfo);
    setWords(INITIALS.words);
  };

  const deleteCardsFromFirebase = async () => {
    try {
      await deleteCards(editingMode);

      store.addNotification({
        title: 'Успешно',
        message: 'Набор удален',
        type: 'success',
        container: 'bottom-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });

      clearState();
      getMyCardsFromFirebase();
    } catch (err) {
      store.addNotification({
        title: 'Не успешно',
        message: 'Не удалось удалить, попробуйте позже',
        type: 'danger',
        container: 'bottom-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  };

  return (
    <CardsView
      cardsList={cardsList}
      cardsListLoading={cardsListLoading}
      newCardInfo={newCardInfo}
      handleCardTitleChange={handleCardTitleChange}
      saveLoading={saveLoading}
      saveNewCards={saveNewCards}
      handleGroupsChange={handleGroupsChange}
      groupsList={groupsList}
      groupIdMap={groupIdMap}
      words={words}
      onWordChange={onWordChange}
      onTranslateChange={onTranslateChange}
      handleAddWord={handleAddWord}
      handleRemoveWord={handleRemoveWord}
      openCardsToEdit={openCardsToEdit}
      clearState={clearState}
      deleteCards={deleteCardsFromFirebase}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CardsContainer);
