import React, { useEffect } from 'react';
import ReactNotification from 'react-notifications-component';

import './style.css';
import Menu from '../../components/menu/Menu';
import EditorView from '../../components/editor/Editor';
import Container from '../../components/container';
import { Route, Redirect } from 'react-router-dom';
import GroupsContainer from '../../modules/groups/GroupsContainer';
import DashboardContainer from '../../modules/dashboard/DashboardContainer';
import MaterialsContainer from '../../modules/materials/MaterialsContainer';
import TasksContainer from '../../modules/tasks/TasksContainer';
import HeaderContainer from '../../modules/header/HeaderContainer';
import AddGroupContainer from '../../modules/addGroup/AddGroupContainer';
import CardsContainer from '../../modules/cards/CardsContainer';

const MainView = props => {
  return (
    <div className="main-container">
      <HeaderContainer />
      <Container>
        <div className="main-wrapper">
          <Menu />
          <div className="content">
            <Route path="/" render={() => <Redirect to="/groups" />} />
            <Route path="/dashboard" render={() => <DashboardContainer />} />
            <Route path="/groups" render={() => <GroupsContainer />} />
            <Route path="/addgroup" render={() => <AddGroupContainer />} />
            <Route path="/materials" render={() => <MaterialsContainer />} />
            <Route path="/tasks" render={() => <TasksContainer />} />
            <Route path="/cards" render={() => <CardsContainer />} />
          </div>
        </div>
      </Container>
      <ReactNotification />
    </div>
  );
};

export default MainView;
