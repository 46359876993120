import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import './App.css';
import './fonts/stylesheet.css';
import { FIREBASE_CONFIG } from './core/firebase';
import Routers from './Routers';

const App = props => {
  const [loadingApp, setLoadingApp] = useState(true);
  useEffect(() => {
    firebase.initializeApp({
      apiKey: FIREBASE_CONFIG.API_KEY,
      authDomain: FIREBASE_CONFIG.AUTH_DOMAIL,
      databaseURL: FIREBASE_CONFIG.DATABASE_URL,
      projectId: FIREBASE_CONFIG.PROJECT_ID,
      storageBucket: FIREBASE_CONFIG.STORAGE_BUCKET,
    });
    setLoadingApp(false);
  }, []);
  if (loadingApp) return null;
  return <Routers />;
};

export default App;
