import React from 'react';
import './style.css';

const Button = ({
  textButton = 'button',
  onClick = () => {},
  loading = false,
  type = 'primary',
  small = false,
  disabled = false,
}) => {
  const sizeClass = small ? 'button-small' : 'button-big';

  switch (type) {
    case 'plus':
      return (
        <button
          disabled={disabled}
          onClick={onClick}
          className={`button-container button-plus ${sizeClass}`}>
          {loading ? 'Загрузка...' : textButton}
        </button>
      );
    case 'info':
      return (
        <button
          disabled={disabled}
          onClick={onClick}
          className={`button-container button-info ${sizeClass}`}>
          {loading ? 'Загрузка...' : textButton}
        </button>
      );
    case 'secondary':
      return (
        <button
          disabled={disabled}
          onClick={onClick}
          className={`button-container button-secondary ${sizeClass}`}>
          {loading ? 'Загрузка...' : textButton}
        </button>
      );
    default:
    case 'primary':
      return (
        <button
          disabled={disabled}
          onClick={onClick}
          className={`button-container button-primary ${sizeClass}`}>
          {loading ? 'Загрузка...' : textButton}
        </button>
      );
  }
};

export default Button;
