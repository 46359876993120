import React, { useState, useEffect } from 'react';
import { store } from 'react-notifications-component';
import './style.css';
import Button from '../button';
import { addInvitCode, getOldInviteCodes } from '../../core/firebase';
import { useHistory } from 'react-router-dom';

const AddStudentToGroup = props => {
  let history = useHistory();
  const [inviteCide, setInviteCode] = useState('');
  const [oldInviteCides, setOldInviteCodes] = useState(null);
  useEffect(() => {
    getOldInviteCodes(props.groupId)
      .then(res => {
        if (res) {
          setOldInviteCodes(res);
        }
      })
      .catch(err => {
        store.addNotification({
          title: 'Ошибка',
          message: err.message,
          type: 'danger',
          container: 'bottom-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });
  }, []);
  const addInviteCode = async () => {
    try {
      const code = makeCode();
      await addInvitCode(code, props.groupId);
      // setInviteCode(code);
      setOldInviteCodes(old => {
        return [...old, { code }];
      });
    } catch (err) {
      console.log(err);
    }
  };

  const makeCode = () => {
    var text = '';
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  };

  return (
    <div className="addStudent">
      <div className="inviteCode-title">Неиспользованные инвайт-коды:</div>
      <div className="inviteCode-list">
        {oldInviteCides &&
          oldInviteCides.map(code => (
            <div className="inviteCode">{code.code}</div>
          ))}
      </div>
      <Button
        onClick={() => addInviteCode()}
        textButton="Сгенерировать инвайт-код"
      />
      {/* {inviteCide && <div className="inviteCode">{inviteCide}</div>} */}
    </div>
  );
};

export default AddStudentToGroup;
