import React, { useState, useEffect } from 'react';
import ReactNotification from 'react-notifications-component';
import './App.css';
import Container from './components/container';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthContainer from './pages/auth/AuthContainer';
import RegistrationContainer from './pages/registration/RegistrationContainer';
import MainContainer from './pages/main/MainContainer';
import 'react-notifications-component/dist/theme.css';

const Routers = props => {
  if (props.userData) {
    return (
      <div className="App app-container">
        <Route path="/" render={() => <MainContainer />} />
      </div>
    );
  } else {
    return (
      <div className="App app-container">
        <Container>
          <Route path="/" render={() => <Redirect to="/auth" />} />
          <Route exact path="/auth" render={() => <AuthContainer />} />
          <Route
            exact
            path="/registration"
            render={() => <RegistrationContainer />}
          />
        </Container>
        <ReactNotification />
      </div>
    );
  }
};
const mapStateToProps = state => ({
  userData: state.user.userData,
});
export default connect(mapStateToProps, null)(Routers);
