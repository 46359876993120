import React from 'react';

const TasksIcon = () => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill="#A0A5B9"
  	viewBox="0 0 512 512"
  >
  <g>
  	<g>
  		<path d="M347,61h-46V45c0-8.284-6.716-15-15-15h-47.57C232.239,12.539,215.556,0,196,0c-19.556,0-36.239,12.539-42.43,30H105
  			c-8.284,0-15,6.716-15,15v16H45C20.187,61,0,81.187,0,106v361c0,24.813,20.187,45,45,45h302c24.813,0,45-20.187,45-45V106
  			C392,81.187,371.813,61,347,61z M120,60h46c8.284,0,15-6.716,15-15c0-8.271,6.729-15,15-15s15,6.729,15,15c0,8.284,6.716,15,15,15
  			h45v16c0,8.271-6.729,15-15,15H135c-8.271,0-15-6.729-15-15V60z M362,467c0,8.271-6.729,15-15,15H45c-8.271,0-15-6.729-15-15V106
  			c0-8.271,6.729-15,15-15h47.58c6.192,17.459,22.865,30,42.42,30h121c19.555,0,36.228-12.541,42.42-30H347c8.271,0,15,6.729,15,15
  			V467z"/>
  	</g>
  </g>
  <g>
  	<g>
  		<path d="M176.693,260.482c-5.808-5.906-15.306-5.985-21.212-0.175l-50.394,49.568l-19.481-19.481
  			c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213l30.001,30C97.321,344.534,101.16,346,105,346
  			c3.798,0,7.599-1.434,10.518-4.306l61-60C182.424,275.885,182.502,266.388,176.693,260.482z"/>
  	</g>
  </g>
  <g>
  	<g>
  		<path d="M176.693,365.482c-5.808-5.906-15.306-5.985-21.212-0.175l-50.394,49.568l-19.481-19.481
  			c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213l30.001,30C97.321,449.534,101.16,451,105,451
  			c3.798,0,7.599-1.434,10.518-4.306l61-60C182.424,380.885,182.502,371.388,176.693,365.482z"/>
  	</g>
  </g>
  <g>
  	<g>
  		<path d="M145.607,215.394L126.213,196l19.393-19.393c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0
  			L105,174.787l-19.394-19.393c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213L83.787,196l-19.393,19.393
  			c-5.858,5.858-5.858,15.356,0,21.214c5.857,5.857,15.355,5.858,21.213,0L105,217.213l19.394,19.394
  			c5.857,5.857,15.355,5.858,21.213,0C151.465,230.749,151.465,221.252,145.607,215.394z"/>
  	</g>
  </g>
  <g>
  	<g>
  		<path d="M317,181h-91c-8.284,0-15,6.716-15,15s6.716,15,15,15h91c8.284,0,15-6.716,15-15S325.284,181,317,181z"/>
  	</g>
  </g>
  <g>
  	<g>
  		<path d="M317,286h-91c-8.284,0-15,6.716-15,15s6.716,15,15,15h91c8.284,0,15-6.716,15-15S325.284,286,317,286z"/>
  	</g>
  </g>
  <g>
  	<g>
  		<path d="M317,391h-91c-8.284,0-15,6.716-15,15s6.716,15,15,15h91c8.284,0,15-6.716,15-15S325.284,391,317,391z"/>
  	</g>
  </g>
  <g>
  	<g>
  		<path d="M467,91c-24.813,0-45,20.187-45,45c0,9.542,0,230.112,0,240c0,1.614,0.289,3.286,0.754,4.697l30,91
  			C454.781,477.845,460.525,482,467,482c6.475,0,12.219-4.155,14.246-10.303l30-91C511.75,379.165,512,377.465,512,376
  			c0-9.872,0-230.419,0-240C512,111.187,491.813,91,467,91z M467,419.091l-12.388-37.576c3.792-1.924,8.012-2.942,12.388-2.942
  			c4.376,0,8.596,1.018,12.388,2.942L467,419.091z M482,350.558c-4.832-1.299-9.862-1.985-15-1.985s-10.168,0.685-15,1.984V211h30
  			V350.558z M482,181h-30v-45c0-8.271,6.729-15,15-15s15,6.729,15,15V181z"/>
  	</g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  </svg>
);

export default TasksIcon;
