import React from 'react';
import './style.css';

const QuestionInput = ({
  placeholder = '',
  withoutCheckbox = false,
  onChange = () => {},
  onRightChange = () => {},
  value = '',
  checked = false,
}) => {
  return (
    <div className="question-input-container">
      <input
        value={value}
        type="text"
        className="question-input"
        placeholder={placeholder}
        onChange={onChange}
      />
      {!withoutCheckbox && (
        <input
          checked={checked}
          type="checkbox"
          className="question-checkbox"
          onChange={e => {
            onRightChange(e);
          }}
        />
      )}
    </div>
  );
};

export default QuestionInput;
