import React from 'react';
import Loader from '../../components/loader/Loader';
import './style.css';

const DashboardView = props => {
  if (props.loading) {
    return <Loader />;
  }

  return (
    <div className="dashboard">
      <div className="block">
        <p className="number">
          {props.students.length}
        </p>
        <span className="title">
          Общее количество студентов
        </span>
      </div>
      <div className="block">
        <p className="number">
          {props.averageCapacity()}
        </p>
        <span className="title">
          Средняя успеваемость
        </span>
      </div>
      <div className="block">
        <p className="number">
          {props.averageTests()}%
        </p>
        <span className="title">
          Средний процент прохождения тестов
        </span>
      </div>
    </div>
  );
};

export default DashboardView;
