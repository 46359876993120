import React from 'react';

import './style.css';
import Button from '../../components/button';
import Loader from '../../components/loader/Loader';
import TestType from '../../components/create-test/test-type';
import QuestionInput from '../../components/create-test/add-question/question-input';

const renderCardsList = props => props.cardsList.map(item => (
    <div
      className="card-list-container"
      onClick={() => {
        props.openCardsToEdit(item.id);
      }}>
      {item.title}
    </div>
  ));

const renderGroups = props =>
  props.groupsList.map(item => (
    <TestType
      text={item.groupInfo.name}
      type={item.id}
      active={props.groupIdMap.has(item.id)}
      onStateChange={(type, isActive) => {
        props.handleGroupsChange(type, isActive);
      }}
    />
  ));

const renderCards = props =>
  props.words.map((item, index) => (
    <div className="word-container">
      <div className="add-word-first-container">
        <div className="add-question-id">{index + 1}</div>
        <Button
          small
          type="secondary"
          textButton="-"
          onClick={() => {
            props.handleRemoveWord(index);
          }}
        />
      </div>

      <div className="add-word-second-container">
        <QuestionInput
          withoutCheckbox
          value={props.words[index].word}
          placeholder="Введите слово"
          onChange={e => {
            props.onWordChange(e, index);
          }}
        />
        <QuestionInput
          withoutCheckbox
          value={props.words[index].translate}
          placeholder="Введите перевод"
          onChange={e => {
            props.onTranslateChange(e, index);
          }}
        />
      </div>
    </div>
  ));

const CardsView = props => {
  return (
    <div className="cards-container">
      <div className="cards-list-container">
        {props.cardsList.length === 0 && (
          <div className="cards-list-empty-container">
            <img
              src={require('../../assets/pictures/tests-list.svg')}
              alt="Список наборов пуст"
            />
            {props.cardsListLoading && <Loader />}
            {!props.cardsListLoading && (
              <div className="cards-list-empty-sign">
                На данный момент нет наборов слов
              </div>
            )}
          </div>
        )}
        {props.cardsList.length > 0 && (
          <>
            <div className="add-new-cards-button-container">
              <Button
                small
                textButton="Добавить набор"
                onClick={props.clearState}
              />
            </div>
            {renderCardsList(props)}
          </>
        )}
      </div>
      <div className="new-card-container">
        <div className="new-test-header">
          <div className="new-test-header-left">
            <div className="new-test-title-container">
              <input
                type="text"
                class="new-test-title"
                placeholder="Новый набор"
                onChange={e => props.handleCardTitleChange(e)}
                value={props.newCardInfo.title}
              />
            </div>
          </div>
          <div className="new-test-header-right">
            {props.newCardInfo.id && (
              <div className="new-test-cancel-container">
                <Button
                  type="secondary"
                  small
                  textButton="Удалить набор"
                  disabled={props.saveLoading}
                  onClick={props.deleteCards}
                />
              </div>
            )}
            <div className="new-test-save-container">
              <Button
                small
                textButton="Сохранить"
                onClick={props.saveNewCards}
                disabled={props.saveLoading}
              />
            </div>
          </div>
        </div>

        <div className="new-cards-groups-container">
          <div className="new-cards-groups-title">Группы</div>
          <div className="new-cards-groups-list-container">
            {renderGroups(props)}
          </div>
        </div>

        <div className="new-test-questions-container">
          <div className="new-test-questions-header">
            <div className="new-test-questions-header-left">
              <div className="new-test-questions-title">Карточки слов</div>
            </div>
            <div
              onClick={props.handleAddWord}
              className="group-info__addQuestion">
              <img
                className="group-info__addQuestion__icon"
                src={require('../../assets/pictures/plus.svg')}
                alt="Новая карточка"
              />
            </div>
          </div>
          {renderCards(props)}
        </div>
      </div>
    </div>
  );
};

export default CardsView;
