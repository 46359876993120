import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getStudents } from '../../core/firebase';
import DashboardView from './DashboardView';

const DashboardContainer = props => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStudents().then(res => {
      if (res) {
        const _students = res.map(el => el.data());
        setStudents(_students.filter(el => el.group));
      }
      setLoading(false);
    });
  }, []);

  const averageCapacity = () => {
    let capacitySum = 0;
    let itemsFound = 0;
    const len = students.length;
    let item = null;
    for (let i = 0; i < len; i++) {
      item = students[i];
      if (item) {
        capacitySum = item.performance + capacitySum;
        itemsFound = itemsFound + 1;
      }
    }
    const averageCapacity = capacitySum / itemsFound;
    return averageCapacity.toFixed(2);
  };

  const averageTests = () => {
    let testsSum = 0;
    let itemsFound = 0;
    const len = students.length;
    let item = null;
    for (let i = 0; i < len; i++) {
      item = students[i];
      if (item) {
        testsSum = item.progress + testsSum;
        itemsFound = itemsFound + 1;
      }
    }
    const averageTests = testsSum / itemsFound;
    return averageTests.toFixed(2);
  };

  return (
    <DashboardView
      loading={loading}
      students={students}
      averageCapacity={averageCapacity}
      averageTests={averageTests}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
