import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import GroupsView from './GroupsView';
import { setUserData } from '../../store/reducers/user-reducer';
import {
  getStudentsFromGroup,
  getGroups,
  deleteUser,
} from '../../core/firebase';
import { setGroups } from '../../store/reducers/groups-reducer';

const GroupsContainer = props => {
  const [groupId, setGroupId] = useState(null);
  const [students, setStudents] = useState([]);
  const [addStudentOpen, setAddStudentOpen] = useState(false);
  const [activeGroup, setActiveGroup] = useState(null);

  useEffect(() => {
    getGroups(props.userData.id).then(res => {
      props.setGroups(res);
    });
    if (props.groups) {
      setGroupId(props.groups[0].id);
    }
  }, []);

  useEffect(() => {
    if (groupId) {
      setAddStudentOpen(false);
      setActiveGroup(props.groups.find(el => el.id === groupId));
      getStudentsFromGroup(groupId).then(res => {
        if (res) {
          setStudents(res.map(el => el.data()));
        }
      });
    }
  }, [groupId]);

  const deleteStudent = id => {
    deleteUser(id, () => {
      let __students = students.slice(0);
      setStudents(__students.filter(item => item.id !== id));
    });
  };

  return (
    <GroupsView
      activeGroup={activeGroup}
      students={students}
      groups={props.groups}
      setGroupId={setGroupId}
      setAddStudentOpen={setAddStudentOpen}
      addStudentOpen={addStudentOpen}
      groupId={groupId}
      deleteStudent={deleteStudent}
    />
  );
};

const mapStateToProps = state => ({
  userData: state.user.userData,
  groups: state.groups,
});

const mapDispatchToProps = dispatch => ({
  setUserData: userData => dispatch(setUserData(userData)),
  setGroups: groups => dispatch(setGroups(groups)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer);
