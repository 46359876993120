import React from 'react';
import './style.css';
import Container from '../../components/container';
import Logo from '../../components/logo/Logo';
import SearchInput from '../../components/searchInput/SearchInput';
import UserBlockContainer from '../../components/userBlock/UserBlockContainer';

/**

<SearchInput placeholder="Поиск по группам, материалам..." />

 */

const HeaderView = props => {
  return (
    <div className="header-container">
      <Container>
        <div className="header-wrapper">
          <Logo />
          <UserBlockContainer userData={props.userData} />
        </div>
      </Container>
    </div>
  );
};

export default HeaderView;
