import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import MainView from './MainView';
import { getUserData } from '../../core/firebase';
import { setUserData } from '../../store/reducers/user-reducer';

const MainContainer = props => {
  useEffect(() => {
    getUserData(props.userData.id).then(res => {
      props.setUserData(res);
    });
  }, []);

  return <MainView />;
};

const mapStateToProps = state => ({
  userData: state.user.userData,
});

const mapDispatchToProps = dispatch => ({
  setUserData: userData => dispatch(setUserData(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
