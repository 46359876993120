import React, { useState, useEffect } from 'react';
import './style.css';
//  tabs example = [
//   {
//      id: '123',
//      name: 'Главная'
//   }
// ]
const Tabs = ({ tabs = null, handleChangeGroup = () => {} }) => {
  const [activeTab, setActiveTab] = useState(null);
  useEffect(() => {
    if (tabs && tabs.length > 0) {
      setActiveTab(tabs[0].id);
    }
  }, []);
  return (
    <div className="tabs-container">
      {tabs &&
        tabs.length > 0 &&
        tabs.map(tab => (
          <div
            onClick={() => {
              if (activeTab !== tab.id) {
                setActiveTab(tab.id);
                handleChangeGroup(tab.id);
              }
            }}
            key={tab.id}
            className={`tabs-item ${
              activeTab === tab.id ? 'tabs-item_active' : ''
            }`}>
            {tab.name}
          </div>
        ))}
    </div>
  );
};

export default Tabs;
