import React from 'react';
import './style.css';
const Bell = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7399 4.10682V3.7398C13.7399 2.77894 12.9609 2 12.0001 2V2C11.0392 2 10.2603 2.77894 10.2603 3.7398V4.10682"
      stroke="#A0A5B9"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10 21V21C10 22.1046 10.8954 23 12 23V23C13.1046 23 14 22.1046 14 21V21"
      stroke="#A0A5B9"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4.5 11.6019C4.5 7.45976 7.85786 4.1019 12 4.1019V4.1019C16.1421 4.1019 19.5 7.45976 19.5 11.6019V16.3366H4.5V11.6019Z"
      stroke="#A0A5B9"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <rect
      x="3"
      y="16.3367"
      width="18"
      height="4"
      rx="1.5"
      stroke="#A0A5B9"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
const UserBlockView = props => {
  const { name, lastName } = props.userData;
  return (
    <div className="userBlock-container">
      {/* <Bell /> */}
      <div className="user-info" onClick={props.openUserMenu}>
        <img src="/account.png" className="avatar" alt="account" />
        <div className="name">
          {name} {lastName}
        </div>
        {props.displayUserMenu && (
          <div class="user-menu">
            <div className="submenu-item" onClick={props.logOut}>
              Выход
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserBlockView;
