import React from 'react';

const CardsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="4" y="2" width="13" height="17" rx="2" stroke="#A0A5B9" stroke-width="1.5"/>
  <path d="M16.9346 5H18C19.1046 5 20 5.89543 20 7V20C20 21.1046 19.1046 22 18 22H9C7.89543 22 7 21.1046 7 20V18.9047" stroke="#A0A5B9" stroke-width="1.5"/>
  <path d="M7.20575 12.5001H13.7943" stroke="#A0A5B9" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M8.23712 15.119H12.7629" stroke="#A0A5B9" stroke-width="1.5" stroke-linecap="round"/>
  <circle cx="10.5" cy="7.88104" r="2" stroke="#A0A5B9" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
);

export default CardsIcon;
