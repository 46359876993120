import React from 'react';

import './style.css';
import Button from '../../components/button';
import Loader from '../../components/loader/Loader';

const TableLine = data => (
  <div
    className={`table-line ${
      data.box1 % 2 === 0 ? 'table-line_white' : 'table-line_gray'
    }`}>
    <div className="box box-1 number">{data.box1}</div>
    <div className="box box-2 name">
      <div
        className="table-line__avatar"
        style={{ backgroundImage: `url(${data.allUserData.avatar})` }}></div>
      {data.box2}
    </div>
    <div className="box box-3 performance">{data.box3}</div>
    <div className="box box-4 time">
      {data.box4}
      <span className="fullTime">/102</span>
    </div>
    <div className="box box-5 complete">
      {data.box5}
      <div className="complete-line">
        <div
          className="complete-line_finish"
          style={{ width: `${data.box5}%` }}></div>
      </div>
    </div>
    <div className="box box-6">
      <Button
        type="secondary"
        small
        textButton="Удалить"
        onClick={() => {
          data.deleteStudent(data.allUserData.id);
          console.log('USER!!!', data.allUserData.id);
        }}
      />
    </div>
  </div>
);

const UsersTableView = props => {
  return (
    <div className="table">
      <div className="table-header table-line">
        <div className="box box-1">№</div>
        <div className="box box-2">Студент</div>
        <div className="box box-3">Успеваемость</div>
        <div className="box box-4">Посещение, ч</div>
        <div className="box box-5">Пройдено</div>
        <div className="box box-6">Управление</div>
      </div>
      <div className="table-list">
        {props.loading ? (
          <Loader />
        ) : (
          props.data &&
          props.data.map((user, index) => (
            <TableLine
              key={index}
              box1={index + 1}
              box2={`${user.name} ${user.lastName}`}
              box3={user.performance}
              box4={user.visitTime}
              box5={user.progress}
              allUserData={user}
              deleteStudent={props.deleteStudent}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default UsersTableView;
