import React from 'react';
import Input from '../../components/input';
import Button from '../../components/button';
import './style.css';
import { NavLink } from 'react-router-dom';
import Logo from '../../components/logo/Logo';

const Auth = props => (
  <div className="loginContainer">
    <div className="half left">
      <Logo />
      <div className="loginTitle">Войдите в свой аккаунт</div>
      <Input
        type="email"
        title="Email"
        value={props.email}
        placeholder="Email"
        onChange={props.setEmail}
      />
      <Input
        type="password"
        title="Пароль"
        value={props.password}
        placeholder="Пароль"
        onChange={props.setPassword}
      />
      <Button
        loading={props.loading}
        onClick={() => props.submit()}
        textButton="Войти"
      />
      <NavLink to="/registration" className="moveToRegistration">
        Зарегистрироваться
      </NavLink>
    </div>
    <div className="half right">
      <img
        src={require('../../assets/pictures/school.svg')}
        alt="tLearn by Tapston"
      />
    </div>
  </div>
);

export default Auth;
