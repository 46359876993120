import { combineReducers } from 'redux';
import userReducer from './user-reducer';
import groupsReducer from './groups-reducer';

const reducers = combineReducers({
  user: userReducer,
  groups: groupsReducer,
});

export default reducers;
