import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import UsersTableView from './UsersTableView';

const UsersTableContainer = props => {
  return (
    <UsersTableView deleteStudent={props.deleteStudent} data={props.students} />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTableContainer);
