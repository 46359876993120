import React, { useState } from 'react';
import AuthView from './AuthView';
import firebase from 'firebase/app';
import 'firebase/auth';
import { store } from 'react-notifications-component';
import { setUserData } from '../../store/reducers/user-reducer';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { getUserData } from '../../core/firebase';

const AuthContainer = props => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const submit = async () => {
    try {
      setLoading(true);
      await firebase.auth().signInWithEmailAndPassword(email, password);
      const userData = await getUserData(firebase.auth().currentUser.uid);
      props.setUserData(userData);
      setLoading(false);
      history.push(`/`);
    } catch (err) {
      setLoading(false);
      store.addNotification({
        title: 'Ошибка',
        message: err.message,
        type: 'danger',
        container: 'bottom-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  };
  return (
    <AuthView
      loading={loading}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      submit={submit}
    />
  );
};

const mapStateToProps = state => ({
  userData: state.user.userData,
});
const mapDispatchToProps = dispatch => ({
  setUserData: userData => dispatch(setUserData(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
