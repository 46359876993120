const SET_GROUPS = 'SET_GROUPS';

const initialState = null;

const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUPS:
      return action.groups;
    default:
      return state;
  }
};

export default groupsReducer;

export const setGroups = groups => {
  return dispatch => {
    dispatch({ type: SET_GROUPS, groups });
  };
};
