import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';

import TasksView from './TasksView';
import {
  getGroups,
  createTest,
  getMyTests,
  uploadFile,
  updateTest,
  deleteFile,
  deleteTest,
} from '../../core/firebase';

const SHOW_RIGHT_OPTIONS = ['Не показывать', 'Показывать'];

const normalizeShowRight = option => {
  if (option === SHOW_RIGHT_OPTIONS[1]) {
    return true;
  }
  return false;
};

const INITIALS = {
  newTestInfo: {
    name: '',
    description: '',
    date: '',
    time: '',
    files: {},
    showRight: SHOW_RIGHT_OPTIONS[0],
  },
  newTestQuestions: [
    {
      number: 0,
      answers: [],
    },
    {
      number: 1,
      answers: [],
    },
  ],
};

let questionsNumber = INITIALS.newTestQuestions.length;

const TasksContainer = props => {
  const [myTests, setMyTests] = useState([]);
  const [myTestsLoading, setMyTestsLoading] = useState(true);
  const [groupIdMap, setGroupIdMap] = useState(new Map());

  const [editingMode, setEditingMode] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);
  const [groupsList, setGroupsList] = useState([]);

  const getMyTestsFromFirebase = () => {
    getMyTests(props.state.user.userData.id).then(res => {
      console.log('MY_TESTS!!!', res);
      setMyTests(res);
      setMyTestsLoading(false);
    });
  };

  useEffect(() => {
    getGroups(props.state.user.userData.id).then(res => {
      setGroupsList(res);
    });

    getMyTestsFromFirebase();
  }, []);

  const [file, setFile] = useState(null);
  const [newTestInfo, setNewTestInfo] = useState(INITIALS.newTestInfo);
  const [newTestQuestions, setNewTestQuestions] = useState(
    INITIALS.newTestQuestions
  );

  const openTestToEdit = id => {
    const test = myTests.find(item => item.id === id);

    console.log(test);

    if (test) {
      setNewTestInfo({
        id: test.id,
        name: test.name,
        date: test.date,
        time: test.time,
        teacher: test.teacher,
        type: test.type,
        files: test.files,
        description: test.description,
        showRight: test.showRight
          ? SHOW_RIGHT_OPTIONS[1]
          : SHOW_RIGHT_OPTIONS[0],
      });
      setNewTestQuestions(test.questions);

      let groupsIds = new Map();

      for (let i = 0; i < test.groupId.length; i++) {
        groupsIds.set(test.groupId[i], test.groupId[i]);
      }

      setGroupIdMap(groupsIds);

      setEditingMode(test.id);
    }
  };

  const handleShowRightChange = value => {
    setNewTestInfo({
      ...newTestInfo,
      showRight: value,
    });
  };

  const handleGroupsChange = (type, isActive) => {
    if (isActive) {
      if (!groupIdMap.has(type)) {
        groupIdMap.set(type, type);
      }
    } else {
      groupIdMap.delete(type);
    }
  };

  const handleAddAnswer = questionIndex => {
    let oldQuestions = newTestQuestions.slice(0);
    if (oldQuestions[questionIndex].answers.length < 4) {
      oldQuestions[questionIndex].answers = [
        ...oldQuestions[questionIndex].answers,
        {
          answer: '',
          right: false,
        },
      ];
      setNewTestQuestions(oldQuestions);
    } else {
      alert('Максимум 4 варианта ответа');
    }
  };

  const handleAnswerTextChange = (e, questionIndex, answerIndex) => {
    let oldQuestions = newTestQuestions.slice(0);
    oldQuestions[questionIndex].answers[answerIndex].answer = e.target.value;
    setNewTestQuestions(oldQuestions);
  };

  const handleAnswerRightChange = (e, questionIndex, answerIndex) => {
    let oldQuestions = newTestQuestions.slice(0);
    oldQuestions[questionIndex].answers[answerIndex].right = e.target.checked;
    setNewTestQuestions(oldQuestions);
  };

  const handleTestNameChange = e => {
    setNewTestInfo({
      ...newTestInfo,
      name: e.target.value,
    });
  };
  const handleTestDescriptionChange = e => {
    setNewTestInfo({
      ...newTestInfo,
      description: e.target.value,
    });
  };
  const handleTestStartDateChange = e => {
    setNewTestInfo({
      ...newTestInfo,
      date: e.target.value,
    });
  };
  const handleTestStartTimeChange = e => {
    setNewTestInfo({
      ...newTestInfo,
      time: e.target.value,
    });
  };
  const handleTestTypeChange = type => {
    setNewTestInfo({
      ...newTestInfo,
      type,
    });
  };

  const addQuestionHandle = () => {
    questionsNumber += 1;
    setNewTestQuestions([
      ...newTestQuestions,
      {
        number: questionsNumber,
        answers: [],
      },
    ]);
  };

  const removeQuestionHandle = index => {
    let questions = newTestQuestions.slice(0);
    questions.splice(parseInt(index, 10), 1);
    setNewTestQuestions(questions);
  };

  const handleQuestionTextChange = (e, index) => {
    let questions = newTestQuestions.slice(0);
    questions[index] = {
      ...questions[index],
      question: e.target.value,
    };
    setNewTestQuestions(questions);
  };

  const saveNewTest = () => {
    setSaveLoading(true);

    let requestData = {
      ...newTestInfo,
      groupId: Array.from(groupIdMap.keys()),
      questions: newTestQuestions,
      teacher: props.state.user.userData.id,
      showRight: normalizeShowRight(newTestInfo.showRight),
    };

    if (file) {
      requestData.files = {
        [newTestInfo.type]: file.name,
      };
    }

    if (!editingMode) {
      // Режим добавления нового теста
      createTest(requestData, res => {
        if (res) {
          if (file) {
            uploadFile(
              {
                testId: res.id,
                fileType: newTestInfo.type,
                name: file.name,
                blob: file.blob,
              },
              () => {}
            );
          }
          setSaveLoading(false);
          setEditingMode(res.id);
          setNewTestInfo({
            ...newTestInfo,
            id: res.id,
          });
          store.addNotification({
            title: 'Успешно',
            message: 'Тест упешно добавлен',
            type: 'success',
            container: 'bottom-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
          getMyTestsFromFirebase();
        } else {
          setSaveLoading(false);
          store.addNotification({
            title: 'Ошибка',
            message: 'Ну удалось сохранить тест',
            type: 'success',
            container: 'bottom-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        }
      });
    } else {
      // Режим редактирования
      updateTest(
        {
          id: editingMode,
          ...requestData,
        },
        res => {
          if (file) {
            uploadFile(
              {
                testId: editingMode,
                fileType: newTestInfo.type,
                name: file.name,
                blob: file.blob,
              },
              () => {
                setNewTestInfo({
                  ...newTestInfo,
                  files: {
                    ...newTestInfo.files,
                    [newTestInfo.type]: file.name,
                  },
                });
              }
            );
          }
          store.addNotification({
            title: 'Успешно',
            message: 'Тест упешно сохранен',
            type: 'success',
            container: 'bottom-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
          setSaveLoading(false);
          getMyTestsFromFirebase();
        }
      );
    }
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    const name = file.name;

    const blob = new Blob([file]);

    setFile({
      name,
      blob,
    });
  };

  const deleteTestFile = () => {
    const filePath = `${newTestInfo.type}/${editingMode}/${
      newTestInfo.files[newTestInfo.type]
    }`;

    setSaveLoading(true);

    let requestData = {
      ...newTestInfo,
      groupId: Array.from(groupIdMap.keys()),
      questions: newTestQuestions,
      teacher: props.state.user.userData.id,
      showRight: normalizeShowRight(newTestInfo.showRight),
    };

    requestData.files = {
      [newTestInfo.type]: null,
    };

    deleteFile(
      {
        id: editingMode,
        ...requestData,
      },
      filePath,
      () => {
        store.addNotification({
          title: 'Успешно',
          message: 'Файл упешно удален',
          type: 'success',
          container: 'bottom-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        setNewTestInfo({
          ...newTestInfo,
          files: {
            ...newTestInfo.files,
            [newTestInfo.type]: null,
          },
        });
        setSaveLoading(false);
        getMyTestsFromFirebase();
      }
    );
  };

  const deleteTestFromFirebase = () => {
    const deleteTestConfirmation = window.confirm(
      'Вы действительно хотите удалить этот тест?'
    );

    if (deleteTestConfirmation) {
      deleteTest(newTestInfo.id, () => {
        store.addNotification({
          title: 'Успешно',
          message: 'Тест упешно удален',
          type: 'success',
          container: 'bottom-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        getMyTestsFromFirebase();
        clearState();
      });
    }
  };

  const clearState = () => {
    setGroupIdMap(new Map());
    setEditingMode(false);
    setFile(null);
    setNewTestInfo(INITIALS.newTestInfo);
    setNewTestQuestions(INITIALS.newTestQuestions);
  };

  return (
    <TasksView
      saveLoading={saveLoading}
      groupsList={groupsList}
      newTestInfo={newTestInfo}
      newTestQuestions={newTestQuestions}
      handleTestNameChange={handleTestNameChange}
      handleTestDescriptionChange={handleTestDescriptionChange}
      handleTestStartDateChange={handleTestStartDateChange}
      handleTestStartTimeChange={handleTestStartTimeChange}
      handleTestTypeChange={handleTestTypeChange}
      addQuestionHandle={addQuestionHandle}
      removeQuestionHandle={removeQuestionHandle}
      handleQuestionTextChange={handleQuestionTextChange}
      handleAddAnswer={handleAddAnswer}
      handleAnswerTextChange={handleAnswerTextChange}
      handleAnswerRightChange={handleAnswerRightChange}
      handleGroupsChange={handleGroupsChange}
      handleFileChange={handleFileChange}
      myTestsLoading={myTestsLoading}
      myTests={myTests}
      openTestToEdit={openTestToEdit}
      groupIdMap={groupIdMap}
      deleteTestFile={deleteTestFile}
      deleteTestFromFirebase={deleteTestFromFirebase}
      clearState={clearState}
      handleShowRightChange={handleShowRightChange}
      SHOW_RIGHT_OPTIONS={SHOW_RIGHT_OPTIONS}
      saveNewTest={saveNewTest}
    />
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  // setTest: () => dispatch(test()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);
