import React from 'react';
import './style.css';

const Input = ({
  placeholder = '',
  value = '',
  title = '',
  onChange = () => {},
  type = 'default',
}) => {
  const _onChange = e => {
    const text = e.target.value;
    onChange(text);
  };

  return (
    <div className="inputContainer">
      <div className="inputTitle">{title}</div>
      <input
        type={type}
        className="input"
        placeholder={placeholder}
        value={value}
        onChange={_onChange}
      />
    </div>
  );
};

export default Input;
