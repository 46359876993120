import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { store } from 'react-notifications-component';
import { createUser, checkInviteCode, getUserData } from '../../core/firebase';
import RegistrationView from './RegistrationView';
import { setUserData } from '../../store/reducers/user-reducer';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const RegistrationContainer = props => {
  const history = useHistory();
  const [inviteCode, setInviteCode] = useState('');
  const [inviteCodeRole, setInviteCodeRole] = useState(null);
  const [inviteCodeSuccess, setInviteCodeSuccess] = useState(false);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const submit = async () => {
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      const { uid } = firebase.auth().currentUser;
      await createUser(uid, {
        name,
        lastName,
        email,
        role: inviteCodeRole,
      });
      const userData = await getUserData(uid);
      props.setUserData(userData);
      history.push('/');
    } catch (err) {
      store.addNotification({
        title: 'Error',
        message: err.message,
        type: 'danger',
        container: 'bottom-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  };
  const checkCode = async () => {
    const res = await checkInviteCode(inviteCode);
    if (res) {
      setInviteCodeSuccess(true);
      setInviteCodeRole(res.role);
    } else {
      store.addNotification({
        title: 'Ошибка',
        message: 'Вы ввели неверный код',
        type: 'danger',
        container: 'bottom-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  };
  return (
    <RegistrationView
      inviteCode={inviteCode}
      setInviteCode={setInviteCode}
      inviteCodeSuccess={inviteCodeSuccess}
      checkCode={checkCode}
      submit={submit}
      name={name}
      setName={setName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
    />
  );
};

const mapStateToProps = state => ({
  userData: state.user.userData,
});
const mapDispatchToProps = dispatch => ({
  setUserData: userData => dispatch(setUserData(userData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationContainer);
