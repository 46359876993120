import React from 'react';

const GroupsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 18C7 15.2386 9.23858 13 12 13C14.7614 13 17 15.2386 17 18V19C17 19.5523 16.5523 20 16 20H8C7.44772 20 7 19.5523 7 19V18Z"
      fill="#A0A5B9"
      stroke="#A0A5B9"
      strokeWidth="1.5"
    />
    <path
      d="M2 15.5V16C2 16.5523 2.44772 17 3 17H4.34314C4.45015 17 4.53786 16.9156 4.54597 16.8089C4.72117 14.5036 6.19902 12.5624 8.2451 11.7197C8.40686 11.6531 8.42465 11.4274 8.26373 11.3588C7.72213 11.1278 7.12599 11 6.5 11C4.01472 11 2 13.0147 2 15.5Z"
      fill="#A0A5B9"
      stroke="#A0A5B9"
      strokeWidth="1.5"
    />
    <path
      d="M22 15.5V16C22 16.5523 21.5523 17 21 17H19.6569C19.5498 17 19.4621 16.9156 19.454 16.8089C19.2788 14.5036 17.801 12.5624 15.7549 11.7197C15.5931 11.6531 15.5753 11.4274 15.7363 11.3588C16.2779 11.1278 16.874 11 17.5 11C19.9853 11 22 13.0147 22 15.5Z"
      fill="#A0A5B9"
      stroke="#A0A5B9"
      strokeWidth="1.5"
    />
    <circle
      cx="12"
      cy="9"
      r="2"
      fill="#A0A5B9"
      stroke="#A0A5B9"
      strokeWidth="1.5"
    />
    <circle
      cx="17.4998"
      cy="6.80423"
      r="1.7"
      fill="#A0A5B9"
      stroke="#A0A5B9"
      strokeWidth="1.5"
    />
    <circle
      cx="6.4998"
      cy="6.80425"
      r="1.7"
      fill="#A0A5B9"
      stroke="#A0A5B9"
      strokeWidth="1.5"
    />
  </svg>
);

export default GroupsIcon;
